import * as React from 'react';
import styled from '@emotion/styled';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Typography, AppBar, Box, Button, Menu, MenuItem, Chip, useMediaQuery } from '@mui/material';

import { ReactComponent as LogoOrange } from '../../static/svg/logo_header_orange.svg';
import { ReactComponent as LogoGreen } from '../../static/svg/logo_header_green.svg';
import { ReactComponent as ArrowIcon } from '../../static/svg/arrow_black.svg';
import { ReactComponent as UsersIcon } from '../../static/svg/users.svg';
import { ReactComponent as LogoutIcon } from '../../static/svg/logout.svg';

import { userAtom } from '../atoms/user';
import { getNavLinks } from './utils/links';
import { clubsAtom } from '../atoms/clubs';
import { getClubColorById } from '../../utils/clubs';
import { isUserLoggedAtom } from '../atoms/isUserLogged';
import { orderDataAtom } from '../atoms/order';
import { countersAtom } from '../atoms/counters';
import { localizationAtom } from '../atoms/localization';
import LanguageButton from './languageButton';
import { logout } from '../api/auth';


function Header({
  updateFilter,
  searchParams,
}) {
  const user = useRecoilValue(userAtom);
  const clubs = useRecoilValue(clubsAtom);
  const counters = useRecoilValue(countersAtom);
  const localization = useRecoilValue(localizationAtom);

  const [clubsForSelect, setClubsForSelect] = React.useState([]);
  
  const [, setIsUserLogged] = useRecoilState(isUserLoggedAtom);
  const [, setUser] = useRecoilState(userAtom);
  const [, setOrders] = useRecoilState(orderDataAtom);

  const isMac = useMediaQuery('(max-width: 1440px)');

  const [navLinks, setNavLinks] = React.useState(getNavLinks(user?.is_staff, localization));

  const club = user?.is_staff
    ? searchParams.get('club') || ''
    : user?.club || '';

  const navigate = useNavigate();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleSelectClick = (event) => {
    if (user?.is_staff) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleSelectChange = (clubId) => {
    handleSelectClose();
    updateFilter({ club: clubId || null });
  };

  const handleSelectClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout({
      access: localStorage.getItem('access-token'),
    });
    
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');

    setIsUserLogged(false);
    setUser(null);
    setOrders([]);

    navigate('/login');
  };

  React.useEffect(() => {
    setNavLinks(getNavLinks(user?.is_staff, localization));
  }, [user, localization]);

  React.useEffect(() => {
    if (Object.entries(clubs).length === 0) {
      return;
    }

    const sortedClubs = Object.entries(clubs).sort((a, b) => {
      if (a[1].name?.match(/Circulo/i)) return -1;
      if (b[1].name?.match(/Circulo/i)) return 1;
      return 0;
    });

    setClubsForSelect([
      [null, { short_name: 'all' }],  
      ...sortedClubs,
    ]);
  }, [clubs]);


  return (
    <AppBar position="static" color='secondary' elevation={0}>
      <Container>
        <LeftContainer>
          <Link to='/?status=New'>
            {process.env.REACT_APP_PANEL_NAME === 'clubsbcn.online' ? (
              <LogoOrange width={isMac ? 155 : 186} height={isMac ? 30 : 36} />

            ) : (
              <LogoGreen width={isMac ? 155 : 186} height={isMac ? 30 : 36} />

            )}
          </Link>

          {user?.is_staff ? (
            <ClubSelect
              id="basic-button"
              aria-controls={isMenuOpen ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={isMenuOpen ? 'true' : undefined}
              onClick={handleSelectClick}
              isStaff={user?.is_staff}
              variant={clubs[club]?.name?.toLowerCase() || 'allClubs'}
              sx={{
                border: `1px solid ${getClubColorById(clubs, club) || '#1b2f2b'}`
              }}
            >
              <Typography
                sx={{ 
                  fontWeight: 1000, 
                  textTransform: 'uppercase',
                  color: getClubColorById(clubs, club) || '#1b2f2b',
                }}
              >
                {clubs[club]?.short_name || clubs[club]?.name || 'All'}
              </Typography>

              <ClubStats>
                <ClubStatsValue>{counters?.[club ? club : 'all'].visitors_registered_visit_today || 0}</ClubStatsValue>
                <VerticalDivider />
                <ClubStatsValue>{counters?.[club ? club : 'all'].visitors_registered || 0}</ClubStatsValue>
              </ClubStats>

              {user?.is_staff && (
                <Box
                  sx={{
                    transform: `rotate(${anchorEl ? 180 : 0}deg)`,
                  }}
                >
                  <ArrowIcon />
                </Box>
              )}

            </ClubSelect>

          ) : (
            <Chip
              variant={'club'}
              label={clubs[club]?.short_name || clubs[club]?.name}
              sx={{
                color: clubs[club]?.color,
                border: `1px solid ${clubs[club]?.color}`,
                background: "transparent",
              }}
            />
          )}
        </LeftContainer>

        <CentralContainer>
          {navLinks.map((navItem) => (
            <Link key={navItem.name} to={navItem.url} style={{ textDecoration: 'none' }}>
              <NavLink isActive={location.pathname === navItem.url}>{navItem.name}</NavLink>
            </Link>
          ))}

        </CentralContainer>

        <RightContainer>
          {user?.is_staff ? (
            <Link to='/admins' style={{ textDecoration: 'none' }}>
              <Users>
                <UsersIcon />

                <Typography
                  sx={{
                    color: "#1B2F2B",
                    fontWeight: 500,
                    textTransform: 'none',
                    textDecoration: 'none',

                    'media (max-width: 1440px)': {
                      fontSize: 14
                    },
                  }}
                >Users</Typography>
              </Users>
            </Link>
          ) : (
            <LanguageButton />
          )}

          <LogoutButton onClick={handleLogout}>
            <LogoutIcon />
          </LogoutButton>

        </RightContainer>

      </Container>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleSelectClose}
        sx={{
          marginTop: .25,

          '& .MuiMenu-paper': {
            borderRadius: 4,
            border: "1px solid rgba(27, 47, 43, 0.10)",
            background: "#FFF",
            boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.05)",

          },

          '& .MuiList-root': {
            padding: 0,

          }
        }}
      >
        {
          clubsForSelect.map(([clubId, club]) => {
            const statsValue = {
              registered: counters?.[clubId || 'all'].visitors_registered,
              registeredToday: counters?.[clubId || 'all'].visitors_registered_visit_today,
            };


            return (
              <MenuItem
                key={club.short_name || club.name}
                onClick={() => handleSelectChange(clubId)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: '12px 14px',
                  width: 250,
                  borderBottom: "1px solid rgba(27, 47, 43, 0.10)",

                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 1000,
                    lineHeight: 1,
                    textTransform: "uppercase",
                    color: club.color || '#1b1b1b1',
                  }}
                >
                  {club.short_name || club.name}
                </Typography>
                <ClubStats>
                  <Typography>{statsValue?.registeredToday}</Typography>
                  <VerticalDivider />
                  <Typography>{statsValue?.registered}</Typography>
                </ClubStats>

              </MenuItem>

            )
          }
          )
        }
      </Menu>

    </AppBar>
  );
}
export default Header;

const Container = styled(Box)(({ theme }) => ({
  padding: '6px 18px',
  display: 'flex',
  justifyContent: 'space-between',

  gap: 10
}));

const HorizontalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 20,
}));

const LeftContainer = styled(HorizontalContainer)(({ theme }) => ({
  width: 450,
}));

const CentralContainer = styled(HorizontalContainer)(({ theme }) => ({
  gap: 34,
}));

const RightContainer = styled(HorizontalContainer)(({ theme }) => ({
  width: 450,
  gap: 10,
  justifyContent: 'flex-end'
}));

const ClubSelect = styled(Button)(({ isStaff }) => ({
  height: 30,
  padding: "0 10px",
  borderRadius: 16,

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 10,

  cursor: isStaff ? "pointer" : "auto",
  transition: `background-color .1s linear`,

  '&:hover': {
    backgroundColor: isStaff ? 'rgba(27, 47, 43, 0.04)' : 'unset',
  }
}));

const ClubStats = styled(Box)(({ theme }) => ({
  height: 22,
  padding: "0 8px",
  borderRadius: 12,
  border: "1px solid rgba(27, 47, 43, 0.10)",

  display: "flex",
  alignItems: "center",
  gap: 8,

  '@media (max-width: 1440px)': {
    padding: "0 6px",
    gap: 6,
    height: 16
  }

}));

const ClubStatsValue = styled(Typography)(() => ({
  color: '#1B2F2B',

  '@media (max-width: 1440px)': {
    fontSize: 13,
  },
}));

const VerticalDivider = styled(Box)(({ theme }) => ({
  width: 1,
  height: "100%",
  backgroundColor: 'rgba(27, 47, 43, .1)',
}));

const NavLink = styled(Typography)(({ isActive }) => ({
  color: isActive ? '#1B2F2B' : 'rgba(27, 47, 43, 0.60)',
  textAlign: "center",
  fontSize: 18,
  fontWeight: 1000,
  lineHeight: 1,
  textTransform: "uppercase",
  textDecoration: "none",
  position: 'relative',

  '&:hover': {
    color: isActive ? '#1B2F2B' : 'rgba(27, 47, 43, 0.80)',

  },

  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -10,
    left: 0,

    backgroundColor: '#11BC76',
    borderRadius: 10,
    width: isActive ? '100%' : 0,
    height: 4,
  },

  '@media (max-width: 1440px)': {
    fontSize: 16,
  },

}));

const Users = styled(Button)(({ theme }) => ({
  height: 36,
  padding: "0px 8px",

  display: "flex",
  alignItems: "center",
  gap: 4,

  borderRadius: 16,
  border: "1px solid rgba(27, 47, 43, 0.10)",

  background: "#FFF",

  '@media (max-width: 1440px)': {
    height: 30,
  },

}));

const LogoutButton = styled(Button)(({ theme }) => ({
  boxSizing: 'border-box',
  minWidth: 'unset',
  width: 36,
  height: 36,

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 10,

  backgroundColor: 'transparent',
  borderRadius: 20,
  border: "1px solid rgba(27, 47, 43, 0.10)",
  backdropFilter: "blur(10px)",

  cursor: "pointer",

  '@media (max-width: 1440px)': {
    height: 30,
  },
}));
