import { Chip, Typography } from "@mui/material";
import getFormattedDatetime from "../../utils/getFormattedDatetime";
import Status from "./columns/status";
import Device from "./columns/device";


function getColumns(clubs, handleSelectClick, isMac) {
  const columns = [
    {
      name: 'id',
      width: '40px',
      cell: row => (
        <Typography variant="table-text">
          {row.user.id}
        </Typography>
      )
    },
    {
      name: 'Username',
      cell: row => (
        <Typography variant="table-text">
          {row.user.username}
        </Typography>
      ),
    },
    {
      name: 'Role',
      width: '100px',
      cell: row => (
        <Typography variant="table-text">
          {row.user?.is_staff ? 'Admin' : 'Club'}
        </Typography>
      ),
    },
    {
      name: 'Name',
      cell: row => (
        <Typography
          variant="table-text"
          sx={{
            fontWeight: 700,
          }}>
          {row.user.first_name}
        </Typography>
      ),
    },
    {
      name: 'Club',
      width: '140px',
      cell: (row) => (
        row.user?.is_staff ? (
          null
        ) : (
          <Chip
            label={clubs[row.club]?.short_name || clubs[row.club]?.name}
            variant='club'
            sx={{
              color: clubs[row.club]?.color,
              border: `1px solid ${clubs[row.club]?.color}`,
              backgroundColor: 'transparent',
              fontWeight: 1000,
              height: '26px'
            }}
          />
        )
      ),
    },
    {
      name: 'Last Login',
      cell: row => (
        <Typography
          variant="table-text"
        >
          {getFormattedDatetime(row.last_login)}
        </Typography>
      ),
    },
    {
      name: 'IP',
      cell: row => (
        <Typography
          variant="table-text"
        >
          {row.last_login_IP}
        </Typography>
      ),
    },
    {
      name: 'Device',
      width: '80px',
      cell: row => (
        <Device row={row} isMac={isMac} />
      ),
    },
    {
      name: 'Sessions',
      width: '100px',
      cell: row => (
        <Typography
          variant="table-text"
        >
          {row.sessions}
        </Typography>
      ),
    },
    {
      name: 'Status',
      width: '90px',
      cell: row => <Status handleSelectClick={handleSelectClick} value={row.status} userId={row.user.id} />,
    },
  ];

  return columns;

}

export default getColumns;
