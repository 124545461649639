import DataTable from 'react-data-table-component';
import { Area, CartesianGrid, ComposedChart, Line, LineChart, XAxis, YAxis } from 'recharts';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { Box, Button, styled, useMediaQuery } from '@mui/material';
import axios from 'axios';


import DeleteIcon from '@mui/icons-material/Close';

import Header from '../header';
import AnalyticsHeader from './analyticsHeader';
import CustomDot from './customDot';
import Loading from '../loader';

import './styles/table.css';

import getColumns from './columns';
import tableStyles from './styles/tableStyles';
import { patchRegistered } from '../api/registered';
import { getMonthFromWeeksData, getWeekNumber } from './utils/date';

import { conditionalRowStyles } from './styles/conditionalStyles';
import { getChecboxesData, getLineGraphVerticalPoints, getPickWidth, transformDataForLineChart } from './utils/data';
import { commonGraphBackgroundStyles, commonTableBackgroundStyles } from './styles/commonGraphBackgroundStyles';
import { getBackgroundColors } from './utils/backgroundColor';
import { colorByTitle, graphs } from './consts';
import { useEffect, useState } from 'react';
import { getAnalytics } from '../api/statistic';


const YAxisWidth = 48;
const graphMin = YAxisWidth;


const AnalyticsPage = () => {
  const isMac = useMediaQuery('(max-width: 1440px)');

  const width = isMac ? 1420 : 1708;
  const graphMax = width;
  const pickWidth = getPickWidth(graphMin, graphMax, 104);

  const [searchParams, setSearchParams] = useSearchParams();
  const [columns, setColumns] = useState(null);
  const [endsOfMonths, setEndsOfMonths] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState();
  const [transformedData, setTransformedData] = useState();

  const [year, setYear] = useState(+searchParams.get('year') || new Date().getFullYear());

  const [filters, setFilters] = useState([true, true, true, false, false, false]);
  const [checkboxes, setCheckboxes] = useState([]);

  function updateFilter(newFilters) {
    setSearchParams((prev) => {
      const curr = createSearchParams(prev);

      Object.entries(newFilters).forEach(([key, value]) => {
        if (value !== null) {
          curr.set(key, value);
        } else {
          curr.delete(key);
        }
      });

      return curr;
    });
  };

  function handleRegisterCellChange(evt) {
    data[+searchParams.get('club')][2][+evt.target.name] = +evt.target.value;

    setData(JSON.parse(JSON.stringify(data)));
  }

  function handleRegisterCellBlur(evt) {
    const clubId = searchParams.get('club');

    if (transformedData[clubId][evt.target.name].reg === +evt.target.value) {
      return;
    }

    if (clubId) {
      const value = evt.target.value.length === 0 ? 0 : evt.target.value;

      patchRegistered({
        data: [{
          "year": year,
          "week": evt.target.name,
          "club": clubId,
          "value": value
        }]
      })
        .then(({ data }) => {
          setTransformedData((prev) => {
            const newData = { ...prev };

            data.forEach(({ club_id, value, week, all_clubs }) => {
              const visitors = newData[club_id][week].vis;
              const allVisitors = newData['all'][week].vis;
              const { reg } = value;

              newData[club_id][week].reg = reg;
              newData[club_id][week].cr = (visitors > 0 && reg > 0)
                ? Math.round(reg / visitors * 100)
                : "";

              newData['all'][week].reg = all_clubs;
              newData['all'][week].cr = (allVisitors > 0 && all_clubs > 0)
                ? Math.round(all_clubs / allVisitors * 100)
                : "";
            });

            return newData;
          });

          setData((prev) => {
            const newData = { ...prev };

            data.forEach(({ club_id, value, week, all_clubs }) => {
              const visitors = newData[club_id][1][week];
              const allVisitors = newData['all'][1][week];
              const { reg } = value;

              newData[club_id][2][week] = reg;
              newData[club_id][3][week] = (visitors > 0 && reg > 0)
                ? Math.round(reg / visitors * 100)
                : 0;

              newData['all'][2][week] = all_clubs;
              newData['all'][3][week] = (allVisitors > 0 && all_clubs > 0)
                ? Math.round(all_clubs / allVisitors * 100)
                : 0;
            });

            return newData;
          });

          // setRegistered((prev) => {
          //   const newData = { ...prev };

          //   data.forEach(({ club_id, registered_visitors, week_number, all_clubs }) => {
          //     newData[club_id][week_number] = registered_visitors;
          //     newData['all'][week_number] = all_clubs;
          //   });

          //   return newData;
          // });


        })
        .catch((err) => {
          console.log("Error updating registered guests");
          console.log(err);
        });
    }

  }

  function handleFilterButtonClick(index) {
    const newFilters = [...filters];
    newFilters[index] = !newFilters[index];

    setFilters(newFilters)
  }

  useEffect(() => {
    setIsLoading(true);

    const tempSearchParams = new URLSearchParams(searchParams);
    tempSearchParams.set('year', (tempSearchParams.get('year') || new Date().getFullYear()) - 1);

    axios.all([getAnalytics(searchParams), getAnalytics(tempSearchParams)])
      .then(axios.spread(({ data: analytics }, { data: analyticsForLastYear }) => {
        const weekNumber = getWeekNumber();


        Object.entries(analytics)
          .forEach(([_, analyticParts]) => {
            analyticParts.push({ title: 'cr' })

            new Array(weekNumber).fill(0).forEach((_, weekIndex) => {
              if (analyticParts[2][weekIndex] && analyticParts[1][weekIndex]) {
                analyticParts[3][weekIndex] = Math.round(analyticParts[2][weekIndex] / analyticParts[1][weekIndex] * 100);

              } else {
                analyticParts[3][weekIndex] = 0;
              }
            });
          });


        const transformedData = Object.entries(analytics).reduce((acc, [club, analyticsValue]) => {
          const analyticsForPrevYearValue = analyticsForLastYear[club];

          acc[club] = transformDataForLineChart(analyticsValue, analyticsForPrevYearValue, weekNumber, year);
          return acc;
        }, {});

        setData(analytics);
        setTransformedData(transformedData);
        setIsLoading(false);

      }))
      .catch((error) => {
        console.error("Failed to get analytics");
        console.log(error);
        setIsLoading(false);
      });

    setCheckboxes(getChecboxesData(year));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  useEffect(() => {
    // if (data) {
    setColumns(getColumns(
      width,
      YAxisWidth,
      data,
      handleRegisterCellChange,
      handleRegisterCellBlur,
      searchParams
    ));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, searchParams, data]);

  useEffect(() => {
    setEndsOfMonths(getMonthFromWeeksData(year));

  }, [year]);


  return (
    <Container>
      <Header
        updateFilter={updateFilter}
        searchParams={searchParams}
      />
      <AnalyticsHeader
        updateFilter={updateFilter}
        year={year}
        setYear={setYear}
      />

      {!transformedData || isLoading ? (
        <Loading />
      ) : (
        <Graphs>
          <GraphWrapper>
            {getBackgroundColors(graphMin, pickWidth, endsOfMonths).map(({ color, start, width }, index) => (
              <Box
                key={start}
                sx={{
                  ...commonTableBackgroundStyles,
                  left: start,
                  width: width,


                  backgroundColor: color,
                }}
              />
            ))}

            {endsOfMonths.slice(0, -1).map((num) => (
              <Box
                key={num}
                sx={{
                  position: 'absolute',
                  left: num * pickWidth * 2 + YAxisWidth,
                  right: 'unset',
                  top: 0,
                  bottom: 0,
                  width: '1px',
                  maxWidth: 1,

                  backgroundColor: 'rgba(27, 47, 43, 0.40)',

                }}
              />
            ))}
            {data && columns && (
              <DataTable
                className='table-wrapper'
                columns={columns}
                data={data[searchParams.get('club') || 'all']}
                customStyles={tableStyles}
                conditionalRowStyles={conditionalRowStyles}
              />
            )}

          </GraphWrapper>

          <GraphWrapper>
            <Box sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
              padding: '10px 0',
            }}>

              {getBackgroundColors(graphMin, pickWidth, endsOfMonths).map(({ color, start, width }, index) => (
                <Box
                  key={start}
                  sx={{
                    ...commonGraphBackgroundStyles,
                    left: start,
                    width: width,
                    bottom: 35,

                    backgroundColor: color,
                  }}
                />
              ))}

              {endsOfMonths.slice(0, -1).map((num) => (
                <Box
                  key={num}
                  sx={{
                    position: 'absolute',
                    left: num * pickWidth * 2 - 2 + YAxisWidth,
                    right: 'unset',
                    top: 0,
                    bottom: 35,
                    width: '1px',
                    maxWidth: 1,

                    backgroundColor: 'rgba(27, 47, 43, 0.40)',
                  }}
                />
              ))}

              <LineChart
                width={width - pickWidth}
                height={260}
                data={transformedData[searchParams.get('club') || 'all']}

                style={{
                  fontFamily: 'Gilroy',
                  color: "#1B2F2B",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "100%",
                }}
              >
                <CartesianGrid
                  stroke="rgba(27, 47, 43, 0.10)"
                  verticalPoints={getLineGraphVerticalPoints(graphMin + pickWidth, graphMax + pickWidth)}
                />
                <XAxis
                  dataKey="title"
                  strokeWidth='0'
                  minTickGap={0}
                  height={20}
                  padding={{
                    left: pickWidth * 2 - 2,
                  }}
                />
                <YAxis
                  strokeWidth='0'
                  width={YAxisWidth - pickWidth}
                />

                {graphs.map(({ title }, index) => {
                  if (filters[index]) {
                    return (
                      <Line
                        key={title}
                        type="linear"
                        dataKey={title}
                        stroke={colorByTitle[title].stroke}
                        animationDuration={0}
                        dot={index <= 2 ? <CustomDot title={title} year={year} /> : null}
                        strokeWidth={index <= 2 ? 2 : 1}
                        strokeDasharray={index <= 2 ? "10 0" : "2 5"}
                      />
                    )
                  }

                  return null;
                })}
              </LineChart>
            </Box>
            <Box sx={{
              padding: '0 20px 20px 50px',

              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}>

              {checkboxes.map(({ title, activeColor }, index) => (
                <Box
                  key={title}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: index === 3 ? 3 : 0,
                  }}
                >
                  <FilterButton
                    onClick={() => handleFilterButtonClick(index)}
                    active={filters[index]}
                    activeColor={activeColor}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {title}
                    <DeleteIcon
                      sx={{
                        width: 18,
                        opacity: .5,
                        transform: `rotate(${filters[index] ? 0 : 45}deg)`,

                        transition: 'transform .3s cubic-bezier(0.165, 0.84, 0.44, 1)',
                      }}
                    />
                  </FilterButton>

                </Box>
              ))}

            </Box>
          </GraphWrapper>

          <GraphWrapper
            sx={{
              padding: '10px 0',
              // overflow: 'hidden',
              backgroundColor: 'white'
            }}
          >

            {endsOfMonths.slice(0, -1).map((num) => (
              <Box
                key={num}
                sx={{
                  position: 'absolute',
                  left: num * pickWidth * 2 - 2 + YAxisWidth,
                  right: 'unset',
                  top: 0,
                  bottom: 0,
                  width: '1px',
                  maxWidth: 1,

                  backgroundColor: 'rgba(27, 47, 43, 0.40)',
                }}
              />
            ))}

            <Box sx={{
              ...commonGraphBackgroundStyles,
              left: 0,
              width: graphMin,

              backgroundColor: '#FFFBF4',
            }} />

            <ComposedChart
              width={width - pickWidth}
              height={260}
              data={transformedData[searchParams.get('club') || 'all']}

              style={{
                fontFamily: 'Gilroy',
                color: "#1B2F2B",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "100%",
              }}
            >
              <CartesianGrid
                stroke="rgba(27, 47, 43, 0.10)"
                verticalPoints={getLineGraphVerticalPoints(graphMin + pickWidth, graphMax + pickWidth)}
              />
              <XAxis
                dataKey="title"
                strokeWidth='0'
                height={20}
                padding={{
                  left: pickWidth * 1.5 - 4,
                  // right: pickWidth
                }}
              />
              <YAxis
                strokeWidth='0'
                width={YAxisWidth - pickWidth / 2}
                domain={[0, 100]}
                ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                interval={0}
              />
              <Area
                type="linear"
                dataKey="cr"
                stroke={colorByTitle['cr'].stroke}
                fill={'#86D6B5'}
                animationDuration={200}
                dot={<CustomDot title='cr' year={year} />}
                strokeWidth={2}
              />

            </ComposedChart >
          </GraphWrapper>
        </Graphs>
      )}
    </Container>
  );
};

export default AnalyticsPage;

const Container = styled(Box)(({ theme }) => ({
  maxWidth: 1728,
  width: '100%',
  margin: '0 auto',
}));

const Graphs = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,

  padding: '0 10px',
}));

const GraphWrapper = styled(Box)(({ theme }) => ({
  borderRadius: 16,
  background: "#FFFBF4",
  border: "1px solid rgba(27, 47, 43, 0.10)",
  position: 'relative',

  maxWidth: '1708',
  width: '100%',
  minHeight: 160,

  overflowX: 'auto',
}));

const FilterButton = styled(Button)(({ active, activeColor }) => ({
  minWidth: 10,

  display: 'flex',
  alignItems: 'center',
  gap: 4,

  backgroundColor: active ? activeColor + "10" : "#eee",
  color: "black",
  opacity: active ? 1 : .5,

  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: active ? activeColor + "aa" : "#ccc",
  borderRadius: 20,

  fontSize: 14,
  fontWeight: 'bold',

  padding: '2px 8px 2px 16px',

  cursor: 'pointer',
  transition: 'opacity .2s ease-in-out, background-color .2s ease-in-out',

  '&:hover': {
    backgroundColor: active ? activeColor + "30" : "#ccc",
    opacity: active ? 1 : .7,
  }
}));
